import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Grid, Typography, Alert, FormControlLabel, Checkbox, TextField } from '@mui/material';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import './Reserve.css';

const BuyNowForm = () => {
  const [checked, setChecked] = useState(false);
  const { stocknumber } = useParams();
  const { t } = useTranslation();
  const [machinery, setMachinery] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  // eslint-disable-next-line
  const [submissionError, setSubmissionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    priceID: '',
    nameID: '',
    emailID: '',
    telephoneID: '',
    referenceID: '',
    companyID: '',
    vatID: '',
    produs_adaugat_de: '',
    reserve: 'buynow',
  });



  useEffect(() => {
    const fetchMachinery = async () => {
      try {
        const response = await fetch(`https://gcm-vehicles.co.uk/apiiiwebsite/get_product_by_reference.php?id=${stocknumber}`);
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
  
        if (data.error) {
          setError(data.error);
        } else {
          setMachinery(data);
          setFormData(prevState => ({
            ...prevState,
            referenceID: data.reference,
            produs_adaugat_de: data.produs_adaugat_de
          }));
        }
      } catch (error) {
        setError('Failed to fetch machinery details.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchMachinery();
  }, [stocknumber]);

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSubmissionError(false);

    const formDataToSubmit = new FormData();
    for (const key in formData) {
      formDataToSubmit.append(key, formData[key]);
    }
    

    try {
      const response = await fetch('https://gcm-vehicles.co.uk/apiiiwebsite/save_subbimission.php', {
        method: 'POST',
        body: formDataToSubmit,
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      const data = await response.json();
      console.log('Form submitted successfully:', data);
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage('Error submitting form. Please try again.');
      setSubmissionError(true);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md">
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md">
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  if (submitted) {
    return (
      <Container maxWidth="md"  >
        <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
        <Alert severity="success">
          {t('Your reservation has been submitted successfully!')}
            <br></br>
          <br></br>
          {t('Succesfully123')}
        </Alert>
        <Typography variant="h6" color="success.main">
          <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" className="asdasdads"style={{ background: 'white', marginTop: '%' }}>
      <div style={{ background: '', width: '100%', margin: '0', padding: '0' }}>
        <br />
        <h1 style={{ textAlign: 'center', marginTop: '9%' }} className="bounce-in">
        {t('dreamvehicle')}
        </h1>
        <br />
        <br />
      </div>
      {machinery && (
        <Grid container spacing={2} className="mobildetails">
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h5" style={{ textAlign: 'center' }}>
                {machinery.title}
              </Typography>
              <Table>
                <TableBody style={{ textAlign: 'center' }}>
                  <TableRow>
                   
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1">{t('make')}:</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{machinery.make}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1">{t('price')}:</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{formatPrice(machinery.price)}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1">{t('referencesAsd')}:</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{machinery.reference}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <br />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src={machinery.main_photo}
                alt={machinery.title}
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            </Box>
          </Grid>
        </Grid>
      )}
      <br />
      <br />
      <div
        style={{
          background: 'rgb(217, 237, 247)',
          textAlign: 'center',
          textTransform: 'uppercase',
          padding: '15px',
          color: 'black',
          fontWeight: 'bold',
          fontSize: '20px',
          textDecoration: 'underline',
        }}
      >
        Buy now 
      </div>
        <div style={{textAlign: 'center', color:'red', fontWeight:'bold'}}> <br></br>

        Attention:<br></br>
If you choose to proceed with the "Buy Now" option, please note that you are purchasing this vehicle online. After selecting this option and submitting your details, you will receive an email containing the sales agreement, bank details for payment, and a 14-Day Buy Back Guarantee document.
<br></br><br></br>
If you prefer to view the vehicle in person, viewings are available by appointment only. Please contact us!<br></br><br></br>
        </div>
      <form onSubmit={handleSubmit}>
        <br />
        <Typography variant="h6" gutterBottom style={{ textAlign: 'center' }}>
        {t('mandatory')}
        </Typography>
        <Typography variant="subtitle2" gutterBottom style={{ textAlign: 'center' }}>
          ({t('pleasefillinalldetails')}):
        </Typography>
        <div style={{ color: '#eabb00', fontWeight: 'bold', textAlign: 'left', marginLeft: '0px', marginBottom: '10px', marginTop: '10px' }}>
        {t('personalinformation')}
        </div>
        <TextField
          fullWidth
          id="nameID"
          label={t('Full Name')}
          variant="outlined"
          required
          value={formData.nameID}
          onChange={handleChange}
        />

        <TextField
          fullWidth
          id="addressID"
          label={t('Address (Street, City, ZIP, Country)')}
          variant="outlined"
          required
          value={formData.addressID}
          onChange={handleChange}
        />
<TextField
          fullWidth
          id="produs_adaugat_de"
          label={t('Address (Street, City, ZIP, Country)')}
          variant="outlined"
          required
          value={formData.produs_adaugat_de}
          onChange={handleChange}
          style={{display:'none'}}
        />
        <TextField
          fullWidth
          id="referenceID"
          label="reference"
          variant="outlined"
          required
          type="text"
          style={{ display: 'none' }}
          value={formData.referenceID}
          onChange={handleChange}
        />

        <TextField
          fullWidth
          id="telephoneID"
          label={t('Telephone')}
          variant="outlined"
          required
          value={formData.telephoneID}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          id="emailID"
          label={t('Email')}
          variant="outlined"
          required
          type="email"
          value={formData.emailID}
          onChange={handleChange}
        />

        <div style={{ color: '#eabb00', fontWeight: 'bold', textAlign: 'left', marginLeft: '0px', marginBottom: '10px', marginTop: '10px' }}>
        {t('compannyinformation')}
        </div>
        <div style={{ marginLeft: '0px', textAlign: 'left', alignContent: 'left', marginBottom: '15px' }}>
        <small>{t('pleasenote')}</small>
        </div>

        <TextField
          fullWidth
          id="companyID"
          label={t('Company Name')}
          variant="outlined"
          type="text"
          value={formData.companyID}
          onChange={handleChange}
        />

        <TextField
          fullWidth
          id="vatID"
          label={t('VAT ID.')}
          variant="outlined"
          type="text"
          value={formData.vatID}
          onChange={handleChange}
        />

        <TextField
          fullWidth
          id="addressCompanyID"
          label={t('Address (Street, City, ZIP, Country)')}
          variant="outlined"
          type="text"
          value={formData.addressCompanyID}
          onChange={handleChange}
        />

        

        
        <br />

        <div className="forceWidth">
          <div className="sizeFont" style={{ width: '100%!important', marginBottom: '20px' }}>{t('textFormProduct')}</div>
        </div>
        {errorMessage && (
          <Typography color="error" variant="body2">
            {errorMessage}
          </Typography>
        )}

        <Box style={{ background: '#FF3232' }}>
          <Typography variant="h5" style={{ textAlign: 'center', fontWeight: 'bold',  color:'white' }}>
          {t('buynowprice')}: {machinery ? formatPrice(machinery.price ) : 'N/A'}
          </Typography>
        </Box>
        <Box mt={3} mb={2}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              background: '#d9edf7',
              padding: '15px',
              borderColor: '#d9edf7',
              borderRadius: '4px',
              fontWeight: 'bold',
              color: '#31708f',
              marginTop: '15px',
              marginBottom: '15px',
            }}
          >
            {t('afterbuynow')}.
          </Typography>

          <Typography
            variant="body2"
            color="textSecondary"
            style={{ color: 'black', fontWeight: '500' }}
          >
              {t('transferbuyitnow1')}{' '}
            <span style={{ color: '#4291ce', fontWeight: '700' }}>
            {t('transferbuyitnow2')} 
            </span>{' '}
            <span style={{ color: '#4291ce', fontWeight: '700' }}> </span>{' '}
            {t('transferbuyitnow3')}{' '}
            <span style={{ color: '#4291ce', fontWeight: '700' }}> {t('transferbuyitnow5')}</span>{' '}
            {t('transferbuyitnow4')}
          </Typography>

          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              color: 'black',
              fontWeight: '500',
              marginTop: '10px',
            }}
          >
            {t('receiptpaymentbuynow')}.
          </Typography>
        </Box>

        {error && (
          <Box mt={2}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}

        <Box mt={3} mb={2} p={2} bgcolor="#dff0d8">
          <Typography
            variant="h5"
            gutterBottom
            style={{ fontWeight: '700', color: '#3c763d' }}
          >
            {t('cancellationpolicy')}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ color: '#3c763d' }}
          >
            <Typography variant="h6" component="h4">{t('withdrawright')}</Typography>
            <div style={{ marginBottom: '10px' }}>
            {t('youhave1')}
                        </div>
            <div style={{ marginBottom: '10px' }}>
            {t('youhave2')}
                        </div>
            <div style={{ marginBottom: '10px' }}>
            {t('youhave3')}
            </div>
            <div style={{ marginBottom: '10px' }}>
            {t('youhave4')}
              </div>
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ color: '#3c763d' }}
          >
            <Typography variant="h6" component="h4">{t('consequeces')}</Typography>
            <div style={{ marginBottom: '10px' }}>
            {t('cancelcontract')}
            </div>
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                style={{
                  fontFamily:
                    '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: '400',
                  fontSize: '0.9rem!important',
                }}
                onChange={() => setChecked(!checked)}
                required
              />
            }
            label={t('ihaveread')}
          />
        </Box>
        <div style={{ textAlign: 'center' }}>
          <Button
            style={{
              marginBottom: '10px',
              textAlign: 'center',
              justifyContent: 'center',
              left: 'auto',
              right: 'auto',
            }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={!checked}
          >
            Buy now
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default BuyNowForm;
