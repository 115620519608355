import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import StickyBottomButtons from './stickyButtonsJustMobile'; // Import the new component
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import './footer.css';
import configDetails from './../components/utils/ConfigDetails';
const Footer = () => {
    const { t } = useTranslation(); // Correctly using React hooks text-align: center;  
    const numar = configDetails.wappsw;
    
    const email = configDetails.email;
    const vatnumber = configDetails.vatnumber;
    
    return (
      <Container id='footerIDpaddingtopandbott'style={{ textAlign: 'center', marginTop: '20px', background:'black', padding:'50px', color:'white', width:'100%!important', maxWidth:'100%', paddingBottom: '0px', paddingTop:'20px', bottom:'0'}}>
          <div className='MenuIconNoDesktop'>
      <StickyBottomButtons  phoneNumber={numar} whatsappNumber={email} /> {/* Sticky buttons   Company #: {vatnumber} */}
      </div>
        <Typography>WhatsApp: {numar} | Email: {email}</Typography>
        <Button color="inherit" component={Link} to="/" className="" style={{color:'#ff3232'}}>
          {t('HomePage')} {/* Home */}
        </Button>
        |
      
        <Button color="inherit" component={Link} to="/inventory" className=""  style={{color:'#ff3232'}}>
          Inventory {/* Contact */}
        </Button>
        |
        <Button color="inherit" component={Link} to="/services" className=""  style={{color:'#ff3232'}}>
          Our services {/* Contact */}
        </Button>
        |
          <Button color="inherit" component={Link} to="/about-us" className=""  style={{color:'#ff3232'}}>
          About Us {/* Contact */}
        </Button>
        |
        <Button color="inherit" component={Link} to="/contact" className="" style={{color:'#ff3232'}}>
          {t('ContactPage')} {/* Contact */}
        </Button>
        |
        <Button color="inherit" component={Link} to="/reviews" className="" style={{color:'#ff3232'}}>
          Reviews {/* Contact */}
        </Button>
       <br></br><div style={{visibility:'hidden'}}>asd</div>
        <Typography variant="body2">
         {t('footerCopyright')}
        </Typography>
        <br></br>
      </Container>
    );
  };
  
  export default Footer;
