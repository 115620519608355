// configDetails.js
const configDetails = {
    swFormatted: "+44  7506 696210",
    sw: '+447506696210',
    wappsw: "+447506696210",
    wappswFormatted: "+44 7506 69 6210",
    email: "gcmvehicles@gmail.com",
    email1: "gcmvehicles@gmail.com",
    spain: "+44 7506 69 6210",
    infoEmail: 'gcmvehicles@gmail.com',
    emailSpanish:'gcmvehicles@gmail.com',
    organizationnumber: '15514102',
    vatnumber: '15514102',
    numarSunat: '+44 1392 58 1826',
    numarSunatF: '+44 1392 58 1826',

};

// +44 1392 58 1762
// +441392581762
export default configDetails;



