import React from 'react';

import Container from '@mui/material/Container';

import HomePageLeft from '../components/HomePageLeft';

import '../components/SearchColumn.css';
import '../components/ContactForm.css';
import './cssMachineryDetails.css';


const Export = () => {


  return (
    <Container maxWidth={false} style={{ marginTop: '100px', paddingLeft: '0px' }}>
    
        <div className="two-column-layout paddingTop10">
          <div className="column-left">
            <HomePageLeft />
          </div>
          <div className="column-right">
          <div className="vehicle-details">
          <div className="vehicle-specifications" style={{background:'#ff3232', color:'white'}}>About Us</div>
            <div>
            <img className=" nodisplayonmobile"src='./export.png' width={'100%'} height={'50%'} alt='About us'> 
            </img>
                <p style={{padding:'10px'}}>
                At GCM VEHICLES, we specialise in offering high-quality vehicles at competitive prices, sourced from auctions, repossessions, and surplus inventory across the UK. As an internet-based company, we operate exclusively online, providing a convenient and hassle-free car-buying experience.
                </p> <p style={{padding:'10px'}}>
Each of our vehicles is inspected by manufacturer-trained technicians, undergoes a thorough 109-point inspection, and comes with a 12-month warranty and a new MOT. We’re committed to transparency and quality, ensuring that every vehicle meets the highest standards.
</p> <p style={{padding:'10px'}}>
We see ourselves as partners to our customers, offering exclusive service, personal care, and building trusting relationships. For years, it has been our passion to procure and market the finest vehicles. We connect with people who value quality, performance, and power, and we offer only vehicles we would personally be satisfied with. We also take into account the vehicle’s future resale potential, ensuring that your investment remains sound.
<br></br>
                </p>
            </div>
          </div>
          </div>
        </div>
     
    </Container>
  );
};

export default Export;
